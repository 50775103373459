.instagram-grid {
    img {
        border : 5px solid $white;
        //width      : 150px;
        //height     : 150px;
        object-fit : cover;
    }

    // $tablet
    @media (min-width : $tablet) {
        img {
            //width: 100%;
            //height : 220px;
        }
    }

    // $notebook
    @media (min-width : $notebook) {
        img {
            //height : 190px;
        }
    }
}
