.free-shipping {
    display         : flex;
    justify-content : center;
    align-items     : center;

    svg {
        max-width : 49px;
        height    : auto;
        fill      : $black-three;
    }

    > .infos {
        text-transform : uppercase;
        padding-left   : 10px;

        .big {
            font-weight : 700;
            font-size : 20px;

            // $notebook
            @media (min-width : $notebook) {
                font-size : 22px;
            }
        }

        .small {
            letter-spacing : 1.56px;
            font-size      : 12px;
        }
    }
}
