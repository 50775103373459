.s-footer {
    padding         : 30px 0 80px 0;
    background      : $primary url("../images/bg-footer-pattern.jpg") no-repeat center top;
    background-size : cover;
    position        : relative;

    .logo {
        margin-bottom : 30px;
        text-align    : center;

        img {
            max-width : 180px;
        }

        // $tablet
        @media (min-width : $tablet) {
            img {
                max-width : 200px;
            }
        }

        // $tablet-portrait
        @media (min-width : $tablet-portrait) {
            align-self : center;
        }

        @media (min-width : $notebook) {
            align-self : flex-start;

            img {
                //max-width : 100%;
            }
        }
    }

    .row {
        // 768
        @media (min-width : $tablet) {
            margin-bottom : -30px;
        }

        // $tablet-portrait
        @media (min-width : $tablet-portrait) {
            margin-bottom : 0;
        }
    }

    [class*="col-"] {

        &.-five {
            text-align : center;

            // $tablet
            @media (min-width : $tablet) {
                .button-shipping {
                    margin-top : 40px;
                }
            }

            // $tablet-portrait
            @media (min-width : $tablet-portrait) {
                margin-left : auto;

                .button-shipping {
                    margin-top : 0;
                }
            }

            // $notebook
            @media (min-width : $notebook) {
                margin-left : 0;
            }
        }
    }

    .widget {
        margin-bottom : 30px;
        text-align    : center;

        // 768
        @media (min-width : $tablet) {
            margin-bottom : 40px;
        }

        .title {
            margin-bottom : 15px;

            // 992
            @media (min-width : $tablet-portrait) {
                text-align : left;
            }
        }

        .content {
            p {
                &:last-child {
                    margin-bottom : 0;
                }
            }

            // 992
            @media (min-width : $tablet-portrait) {
                text-align : left;
            }
        }

        .contact {
            font-size     : 30px;
            font-weight   : 700;
            margin-bottom : 10px;

            // 768
            @media (min-width : $tablet) {
                font-size : 26px;
            }

            // 992
            @media (min-width : $tablet-portrait) {
                font-size : 30px;
            }
        }
    }

    .social {
        display         : flex;
        justify-content : center;

        // 992
        @media (min-width : $tablet-portrait) {
            justify-content : flex-start;
        }

        a {
            margin : 0 15px 0 0;

            &:last-child {
                margin-right : 0;
            }

            svg {
                max-width  : 29px;
                height     : auto;
                max-height : 29px;
                transition : all .4s ease;

                fill       : var(--white);
            }

            &:hover {
                svg {
                    fill : var(--secondary);
                }
            }
        }
    }

    .copyright {
        margin-top     : 30px;
        padding-top    : 25px;
        text-align     : center;
        letter-spacing : .4px;
        border-top     : 1px solid rgba(255, 255, 255, .15);
        color          : $white;
        font-size      : 14px;

        a {
            color : $white;
        }

        // 1200
        @media (min-width : $notebook) {
            display         : flex;
            align-items     : center;
            justify-content : space-between;
            padding-top     : 30px;
            padding-left    : 100px;
            padding-right   : 100px;
            font-size       : 16px;
        }

        // $large-desktop
        @media (min-width : $large-desktop) {
            padding-left  : 0px;
            padding-right : 0px;
        }

        .links {
            margin-top : 10px;

            // 1200
            @media (min-width : $tablet-portrait) {
                margin-top : 0;
            }
        }
    }
}
