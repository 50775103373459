// Embeds responsive
.embed-responsive {
    position : relative;
    display  : block;
    overflow : hidden;
    height   : 0;
    padding  : 0;

    .embed-responsive-item, iframe, embed, object, video, .rll-youtube-player iframe {
        position : absolute;
        top      : 0;
        bottom   : 0;
        left     : 0;
        width    : 100%;
        height   : 100%;
        border   : 0;
    }
}

// Modifier class for 16:9 aspect ratio
.embed-responsive-16by9 {
    padding-bottom : 56.25%;
}

// Modifier class for 4:3 aspect ratio
.embed-responsive-4by3 {
    padding-bottom : 75%;
}
