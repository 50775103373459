.s-kits {
    .wrapper {
        max-width    : 1200px;
        margin-left  : auto;
        margin-right : auto;

        // $desktop
        @media (min-width : $desktop) {
            margin-top : 40px;
        }
    }

    .row {
        margin-bottom : -30px;
    }

    .table-item {
        margin-bottom : 30px;

        &.-feature {
            // $desktop
            @media (min-width : $desktop) {
                transform : scale(1.05);
            }
        }
    }
}
