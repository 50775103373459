.s-main {
    background      : #01a7db url("../images/bg-mobile.jpg?v=1.1") repeat-x center top;
    background-size : cover;
    padding-top     : 30px;
    position        : relative;

    // $tablet
    @media (min-width : $tablet) {
        background-image : url('../images/bg.jpg');
        background-size  : cover;
    }

    // $notebook
    @media (min-width : $notebook) {
        //min-height          : 700px;
    }

    // $large-desktop
    @media (min-width : $large-desktop) {
        //min-height          : 721px;
        background-position : center top;
    }

    .header {
        margin-bottom : 20px;

        // $tablet
        @media (min-width : $tablet) {
            margin-bottom : 30px;
        }

        // $notebook
        @media (min-width : $notebook) {
            display         : flex;
            justify-content : space-between;
        }

        .logo {
            text-align : center;

            img {
                max-width : 200px;
            }

            // $notebook
            @media (min-width : $notebook) {
                text-align : left;

                img {
                    max-width : 280px;
                }
            }
        }
    }

    .wrapper {
        display        : flex;
        flex-direction : column;

        // $notebook
        @media (min-width : $notebook) {
            display        : flex;
            flex-direction : row;
            min-height     : 470px;
        }

        // $extra-large-desktop
        @media (min-width : $large-desktop) {
            min-height : 400px;
        }

        > .infos {
            // $tablet
            @media (min-width : $tablet) {
            }

            // $notebook
            @media (min-width : $notebook) {
                max-width      : 65%;
                flex           : 1;
                align-self     : center;
                position       : relative;
                z-index        : 1;
                padding-bottom : 30px;
            }

            // $large-desktop
            @media (min-width : $large-desktop) {
                max-width : 57%;
            }

            > .title {
                text-align    : center;
                font-size     : 30px;
                color         : $white;
                line-height   : 1.3;
                margin-bottom : 15px;

                // $notebook
                @media (min-width : $notebook) {
                    font-size     : 2.875rem;
                    padding-right : 100px;
                    text-align    : left;
                }

                // $large-desktop
                @media (min-width : $large-desktop) {
                    padding-right : 0px;
                }
            }

            > .image {
                //text-align : center;
                margin-top : 20px;

                img {
                    max-width    : calc(100vw - 40px);
                    position     : relative;
                    margin-left  : auto;
                    margin-right : auto;
                    left         : 50%;
                    transform    : translateX(-50%);
                }

                // $tablet
                @media (min-width : $tablet) {
                    display : none;
                }
            }

            > .entry {
                text-align : center;
                color      : $white;

                h1, h2, h3, h4, h5, h6 {
                    line-height : 1.3;
                }

                p {
                    &:last-child {
                        margin-bottom : 0;
                    }
                }

                // $tablet
                @media (min-width : $tablet) {
                    max-width    : 70%;
                    margin-left  : auto;
                    margin-right : auto;
                }

                // $notebook
                @media (min-width : $notebook) {
                    max-width    : 90%;
                    text-align   : left;
                    margin-right : initial;
                    margin-left  : initial;
                }

                // $desktop
                @media (min-width : $desktop) {
                    //font-size : 24px;
                }
            }

            > .action {
                margin-top : 20px;
                text-align : center;
                //display    : none;
                //position: absolute;
                z-index    : 1;
                bottom     : 1.875rem;
                left       : 0;
                right      : 0;

                // $notebook
                @media (min-width : $notebook) {
                    display    : block;
                    text-align : left;
                    margin-top : 40px;
                    position   : relative;
                    bottom     : initial;
                    z-index    : initial;
                    left       : initial;
                    right      : initial;
                }
            }
        }

        > .images {
            display : none;

            // $tablet
            @media (min-width : $tablet) {
                display    : block;
                align-self : flex-end;
                text-align : center;
                margin-top : 20px;
            }

            // $notebook
            @media (min-width : $notebook) {
                margin-top : -100px;
                flex       : 1;
            }

            .image-big {
                // $tablet
                @media (min-width : $tablet) {
                    max-width : 70%;
                }

                // $notebook
                @media (min-width : $notebook) {
                    max-width : 130%;
                    transform : translateX(-10%);
                }
            }
        }
    }

    .white-wave {
        display : none;

        // $notebook
        @media (min-width : $notebook) {
            margin-top : 100px;
        }

        svg {
            position     : absolute;
            bottom       : -1px;
            width        : 150vw;
            left         : 50%;
            margin-left  : auto;
            margin-right : auto;
            transform    : translateX(-50%);
        }
    }
}
