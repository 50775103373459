.coupon {
    background-color : $success;
    margin-bottom    : 1.25rem;
    padding          : 12px 24px;
    border-radius    : 5px;
    font-weight      : 700;
    display          : inline-block;
    color            : $white;
}

// animation pulse
.coupon {
    animation-name   : pulse;
    animation-duration: 1s;
    animation-iteration-count: infinite;
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}

