
.checklist {
    list-style : none;
    margin     : 0;
    padding    : 0;

    li {
        display       : flex;
        margin-bottom : 15px;

        &:before {
            flex                : 0 0 20px;
            margin-right        : 10px;
            align-self          : flex-start;
            margin-top          : 2px;
            content             : '';
            width               : 20px;
            height              : 20px;
            background          : url("../images/check.png") no-repeat center center;
            background-size     : cover;
            background-position : center center;
        }

        // $tablet
        @media (min-width : $tablet) {
            margin-bottom : 20px;

            &:before {
                flex   : 0 0 28px;
                width  : 28px;
                height : 28px;
                margin-top : -2px;
            }
        }
    }
}
