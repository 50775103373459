.testimonial-item {
    background     : $white;
    box-shadow     : 0px 5px 25px 0 rgba(0, 0, 0, 0.05);
    border-radius  : 10px;
    padding-bottom : 20px;

    .image {
        text-align    : center;
        border-radius : 10px 10px 0 0;

        img {
            width : 100%;
        }
    }

    .name {
        text-align      : center;
        justify-content : center;
        display         : flex;
        font-weight     : 700;
        color           : $secondary;
        align-items     : center;
        font-size       : 16px;
        margin-top      : 20px;
        padding-left    : 10px;
        padding-right   : 10px;

        // $tablet
        @media (min-width : $tablet) {
            font-size : 18px;
        }

        svg {
            width       : 20px;
            height      : auto;
            fill        : $irish-green;
            margin-left : 10px;
        }
    }

    .content {
        margin-top    : 10px;
        margin-bottom : 20px;
        padding       : 0 20px;
        text-align    : center;
        font-size     : 0.875rem;

        // $tablet
        @media (min-width : $tablet) {
            color       : $black-three;
            font-weight : 500;
            font-size   : 0.9375rem;
        }

        p {
            &:last-child {
                margin-bottom : 0;
            }
        }
    }

    .stars {
        display         : flex;
        flex-wrap       : wrap;
        justify-content : center;
        align-items     : flex-start;

        svg {
            width  : 20px;
            height : auto;
            fill   : #fac917;
            margin : 0 2px;
        }

        // $tablet
        @media (min-width : $tablet) {
            svg {
                width : 25px;
            }
        }
    }

    .stars .text {
        flex        : 0 0 100%;
        font-weight : 700;
        text-align  : center;
        font-size   : 13px;
        color       : $greyish;

        // $tablet
        @media (min-width : $tablet) {
            font-size : 14px;
        }
    }
}
