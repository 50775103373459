/**
Leaves and Ground
 */
@font-face {
    font-family  : 'Leaves And Ground';
    src          : url('../../assets/fonts/LeavesAndGround.woff2') format('woff2'),
    url('../../assets/fonts/LeavesAndGround.woff') format('woff');
    font-weight  : normal;
    font-style   : normal;
    font-display : swap;
}

@mixin leaves() {
    font-family : 'Leaves And Ground', sans-serif;
}

.leaves {
    @include leaves;
}


/**
Monteserrat
 */
@mixin montserrat() {
    font-family : 'Montserrat', sans-serif;
}

.montserrat {
    @include montserrat
}

/**
Axiforma
 */
@font-face {
    font-family: 'Axiforma';
    src: url('../../assets/fonts/Axiforma-Light.woff2') format('woff2'),
    url('../../assets/fonts/Axiforma-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Axiforma';
    src: url('../../assets/fonts/Axiforma-Bold.woff2') format('woff2'),
    url('../../assets/fonts/Axiforma-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Axiforma';
    src: url('../../assets/fonts/Axiforma-Heavy.woff2') format('woff2'),
    url('../../assets/fonts/Axiforma-Heavy.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Axiforma';
    src: url('../../assets/fonts/Axiforma-SemiBold.woff2') format('woff2'),
    url('../../assets/fonts/Axiforma-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Axiforma';
    src: url('../../assets/fonts/Axiforma-Regular.woff2') format('woff2'),
    url('../../assets/fonts/Axiforma-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@mixin axiforma {
    font-family: 'Axiforma', sans-serif;
}

.axiforma {
    @include axiforma;
}
