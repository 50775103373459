.fade-in,
.fade-in-left,
.fade-in-right,
.fade-in-down,
.fadein-up {
    opacity    : 0;
    visibility : hidden;
}


/**
Animation keyframe scale in and out
 */
@keyframes scale-in-out {
    0% {
        transform : scale(1);
    }
    100% {
        transform : scale(1.05);
    }
}

// scale class with animation
.scale {
    transition: all .4s ease-in-out;
    animation-name     : scale-in-out;
    animation-duration : 1s;
    animation-iteration-count : infinite;
    animation-fill-mode : forwards;
    animation-direction : alternate;
}
