.fw-light {
    font-weight : 300;
}

.fw-regular,
.fw-normal {
    font-weight : 400;
}

.fw-medium {
    font-weight : 500;
}

.fw-semibold {
    font-weight : 600;
}

.fw-bold {
    font-weight: 700;
}

.fw-extrabold {
    font-weight: 800;
}
