.s-half {
    .image {
        display         : flex;
        justify-content : center;

        img {
            width      : 100vw;
            max-width  : 100vw;
            object-fit : cover;
            position   : relative;
            max-height : 100%;
        }

        // $notebook
        @media (min-width : $notebook) {
            justify-content : flex-end;
            position        : relative;
            display         : block;
            height          : auto;

            img {
                width      : calc(50vw);
                position   : absolute;
                height     : 100%;
                max-width  : 50vw;
                max-height : initial;
                right      : 0px;
            }
        }
    }

    &.-reverse {
        .image {
            // $notebook
            @media (min-width: $notebook) {
                img {
                    right: initial;
                    left: 0px;
                }
            }
        }
    }
}
