body, html {
    overflow-x : hidden;
    position   : relative;
    max-width  : 100%;
}

body {
    font-family : 'Poppins', arial, sans-serif;
    color : $body-color;
    font-weight : 300;

    // $large-desktop
    @media (min-width: $large-desktop) {
        font-size: 1.125rem;
    }
}


::-webkit-scrollbar {
    //height     : 3px;
    width      : 5px; /* Remove scrollbar space */
    background : $gray-200; /* Optional: just make scrollbar invisible */
}

/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
    background : $gray-500;
}
