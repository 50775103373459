.s-list-product {
    background-image    : url('../images/noise.jpg');
    background-repeat   : repeat-x;
    background-position : center bottom;

    .checklist {
        // remove margin botto of the last two items of li
        // $notebook
        @media (min-width : $notebook) {
            li:nth-last-child(1),
            li:nth-last-child(2) {
                margin-bottom: 0;
            }
        }
    }
}
