.s-faq {
    background      : url("../images/bg-faq.png") repeat-x center bottom;
    background-size : auto 100px;
    padding-bottom  : 60px;

    // $tablet
    @media (min-width : $tablet) {
        padding-bottom  : 100px;
        background-size : auto 300px;
    }

    // $tablet-portrait
    @media (min-width : $tablet-portrait) {
        padding-bottom  : 100px;
        background-size : auto 400px;
    }

    // $desktop
    @media (min-width : $desktop) {
        padding-bottom  : 140px;
        background-size : initial;
    }

    .subtitle {
        p {
            &:last-child {
                margin-bottom : 0;
            }
        }
    }

    .row {
        //max-width     : 1170px;
        //margin-left   : auto;
        //margin-right  : auto;
        margin-bottom : -15px;

        // $large-desktop
        @media (min-width: $large-desktop) {
            max-width     : 1170px;
            margin-left   : auto;
            margin-right  : auto;
        }
    }

    .action {
        margin-top : 20px;

        // $tablet
        @media (min-width : $tablet) {
            margin-top : 30px;
        }
    }
}
