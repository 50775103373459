.button {
    color            : $white;
    border-radius    : 10px;
    display          : inline-flex;
    font-weight      : 800;
    justify-content  : center;
    font-size        : 20px;
    padding          : 1rem 2rem;
    background-color : $tealish-green;
    min-width        : 220px;

    // $tablet-portrait
    @media (min-width : $tablet-portrait) {
        padding   : .95em 1.8em;
        font-size : 24px;
    }

    // $notebook
    @media (min-width : $notebook) {
        padding : 1em 1.5em;
        min-width : 300px;
    }

    &:hover {
        background-color : shade-color($tealish-green, 10%);
        color            : $white;
    }

    &.-block {
        display : flex;
        width   : 100%;
    }

    &.-cta {
        // $large-desktop
        @media (min-width : $large-desktop) {
            min-width : 365px;
        }
    }
}
