.video {
    position      : relative;
    border-radius : 15px;
    border        : 8px solid rgba(3,3,3,.15);
    overflow      : hidden;

    // $notebook
    @media (min-width : $notebook) {
        border-width : 10px;
    }

    .thumb {
        border-radius : 5px;
        object-fit    : cover;
        width         : 100%;
        height        : auto;
    }

    .play {
        position        : absolute;
        top             : 0;
        right           : 0;
        bottom          : 0;
        left            : 0;
        display         : flex;
        align-items     : center;
        flex-flow       : column;
        justify-content : center;
        text-align      : center;

        img {
            max-width : 60px;
        }

        // 992
        @media (min-width : $tablet-portrait) {
            img {
                max-width : 80px;
            }
        }

        // 1200
        @media (min-width : $notebook) {
            img {
                max-width : 100px;
            }
        }

        &:hover {
            transform : scale(1.05);
        }
    }
}
