
a {
    transition      : all .4s ease-in-out;
    color           : $link-color;
    text-decoration: none;

    &:hover {
        text-decoration: none;
        color           : $link-hover-color;
    }
}
