/**
Accordion item style
*/
.accordion-item-v2 {
    border-radius : 10px;
    margin-bottom : 15px;
    background    : $white;
    border        : 2px solid #cacaca;
    padding       : 8.5px 20px;

    // $tablet
    @media (min-width : $tablet) {
        padding-top    : 0.9375rem;
        padding-bottom : 0.9375rem;
    }

    > .title {
        font-weight     : 600;
        color           : $black-two;
        display         : flex;
        margin          : 0;
        padding         : 0;
        font-size       : 1rem;
        justify-content : space-between;
        align-items     : center;
        line-height     : 1.4;
        cursor          : pointer;

        // $notebook
        @media (min-width : $notebook) {
            font-size : 1.125rem;
        }
    }

    > .title > .arrow {
        fill         : $success;
        margin-right : -10px;
        margin-top   : -5px;
        align-self   : flex-start;

        svg {
        }

        svg, path {
            width  : 30px;
            height : auto;
        }

        svg, path {
            fill : $primary;
        }
    }

    > .title > .arrow.-minus {
        display : none;
    }

    > .content {
        display  : none;
        color    : $black-two;
        overflow : hidden;
    }

    > .content > .entry {
        padding-top    : 20px;
        padding-bottom : 10px;
        font-size      : 15px;

        // $tablet-portrait
        @media (min-width : $tablet-portrait) {
            font-size : 16px;
        }

        p {
            &:last-of-type,
            &:last-child {
                margin-bottom : 0;
            }
        }
    }
}
