.s-checkout {
    background-image    : url('../images/bg-purple.jpg');
    background-repeat   : repeat-x;
    background-position : center center;
    background-size     : cover;
    border-top          : 30px solid $primary;
    position: relative;

    .docs {
        // $notebook
        @media (min-width : $notebook) {
            max-width : 22.8125rem;
        }
    }


    .white-wave {
        // $notebook
        @media (min-width : $notebook) {
            margin-top : 100px;
        }

        svg {
            position     : absolute;
            bottom       : -1px;
            width        : 150vw;
            left         : 50%;
            margin-left  : auto;
            margin-right : auto;
            transform    : translateX(-50%);
        }
    }
}

