.s-vivo {
    position : relative;

    .logo {
        width : 70%;

        // $notebook
        @media (min-width : $notebook) {
            max-width : 400px;
        }
    }

    .white-wave {
        // $notebook
        @media (min-width : $notebook) {
            margin-top : 100px;
        }

        svg {
            position     : absolute;
            bottom       : -1px;
            width        : 150vw;
            left         : 50%;
            margin-left  : auto;
            margin-right : auto;
            transform    : translateX(-50%);
        }
    }
}
