.s-access {
    background-image: url('../images/bg-white.png');
    background-repeat: repeat-x;
    background-position: center bottom;

    // $notebook
    @media (min-width: $notebook) {
        background-position: top    center;
    }
}
