
/**
Card step
 */
.card-step {
    background-color: rgba(255,255,255,.15);

    svg {
        max-width: 100px;
        height: auto;
    }
}
