.s-video {
    //background-image: url('../images/bg-pattern.jpg');

    // $tablet
    @media (min-width : $tablet) {
        padding-top : 80px;
    }

    // $tablet-portrait
    @media (min-width : $tablet-portrait) {
        padding-top : 100px;
    }

    // $desktop
    @media (min-width : $desktop) {
        padding-top : 120px;
    }

    .wrapper-video {
        position : relative;
        width    : 100%;

        // 992
        @media (min-width : $tablet-portrait) {
            max-width    : 720px;
            margin-right : auto;
            margin-left  : auto;
        }

        // 1200
        @media (min-width : $notebook) {
            max-width : 930px;
        }
    }

    .wrapper-video .video-image {
        img {
            position : absolute;
            display  : none;

            // $tablet-portrait
            @media (min-width : $tablet-portrait) {
                display   : block;
                left      : 100%;
                bottom    : 0;
                max-width : 200px;
                transform : translate(-15%, 5%);
            }

            // $notebook
            @media (min-width : $notebook) {
                max-width : 280px;
            }

            // $desktop
            @media (min-width : $desktop) {
                max-width : 100%;
                transform : translate(-25%, 5%);
            }
        }
    }

    .action {
        margin-top : 20px;

        // $tablet
        @media (min-width : $tablet) {
            margin-top : 30px;
        }
    }
}
