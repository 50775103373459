.table-item {
    padding         : 15px;
    border-radius   : 10px;
    background      : $white-two url("../images/bg-table.jpg") no-repeat center center;
    background-size : cover;

    .header {
        @include leaves;
        background-color : $primary;
        color            : $white;
        padding          : 0px 20px;
        border-radius    : 10px;
        text-align       : center;
        font-size        : 52px;
        line-height      : 1;

        // $tablet
        @media (min-width : $tablet) {
            font-size : 72px;
        }
    }

    .image {
        text-align      : center;
        padding-top     : 15px;
        padding-bottom  : 15px;
        display         : flex;
        align-items     : center;
        justify-content : center;

        // $tablet
        @media (min-width : $tablet) {
            height : 330px;
        }
    }

    .price-before {
        text-align  : center;
        font-weight : 400;
        color       : $green-brown;
        font-size   : 20px;

        // $tablet
        @media (min-width : $tablet) {
            font-size : 24px;
        }
    }

    .value {
        color       : $pea;
        text-align  : center;
        font-size   : 50px;
        font-weight : 800;

        // $tablet
        @media (min-width : $tablet) {
            font-size : 60px;
        }
    }

    .full {
        text-align : center;
        font-size  : 18px;
        margin-top : 5px;

        span {
            padding          : 5px 10px;
            display          : inline-flex;
            background-color : $primary;
            border-radius    : 5px;
            color            : $white;
            font-weight      : 700;
        }

        // $tablet
        @media (min-width : $tablet) {
            font-size : 20px;
        }
    }

    .button {
        margin-top : 15px;
    }
}
