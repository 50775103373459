.s-about {
    .about-infos .container {
        display   : flex;
        flex-flow : column-reverse;

        // $tablet-portrait
        @media (min-width : $tablet-portrait) {
            flex-flow : row;
        }
    }

    .about-infos .infos {
        text-align : center;

        // $tablet-portrait
        @media (min-width : $tablet-portrait) {
            flex       : 1;
            align-self : center;
            text-align : left;
        }
    }

    .about-infos .image {
        margin-bottom : 20px;
        text-align    : center;

        img {
            max-width : 240px;
        }

        // $tablet
        @media (min-width : $tablet) {
            img {
                max-width : 280px;
            }
        }

        // $tablet-portrait
        @media (min-width : $tablet-portrait) {
            max-width     : 45%;
            margin-bottom : 0;

            img {
                max-width : 100%;
            }
        }
    }

    .about-infos .action {
        text-align : center;

        // $tablet-portrait
        @media (min-width : $tablet-portrait) {
            text-align : left;
        }
    }

    .about-one {
        background      : url("../images/bg-about-one.jpg") no-repeat center bottom -1px;
        background-size : auto 200px;
        padding-bottom  : 100px;

        // $tablet
        @media (min-width : $tablet) {
            background-size : auto 240px;
        }

        // $tablet-portrait
        @media (min-width : $tablet-portrait) {
            background-size : auto 300px;
        }

        // $notebook
        @media (min-width : $notebook) {
            background-size : auto 430px;
            padding-bottom  : 190px;
        }

        // $desktop
        @media (min-width : $desktop) {
            background-size : auto 480px;
        }

        // $large-desktop
        @media (min-width : $large-desktop) {
            background-size   : initial;
            background-repeat : repeat-x;
        }
    }

    .about-one .infos {
        // $tablet-portrait
        @media (min-width : $tablet-portrait) {
            padding-right : 40px;
            align-self    : flex-start;
        }
    }

    .about-two {
        background      : $greyish-brown;
        background-size : auto 200px;
        position        : relative;

        &:after {
            bottom          : 0;
            transform       : translateY(40px);
            position        : absolute;
            content         : '';
            left            : 0;
            right           : 0;
            display         : block;
            height          : 40px;
            background      : url("../images/bg-about-two.png") no-repeat center bottom;
            background-size : cover;
            margin-top      : 40px;
        }

        // $tablet
        @media (min-width : $tablet) {
            margin-bottom : 40px;

            &:after {
                transform : translateY(80px);
                height    : 80px;
            }
        }

        // $tablet-portrait
        @media (min-width : $tablet-portrait) {
            padding-top : 30px;
        }

        // $large-desktop
        @media (min-width : $large-desktop) {
            &:after {
                transform : translateY(114px);
                height    : 114px;
            }
        }
    }

    .about-two .container {
        // $tablet-portrait
        @media (min-width : $tablet-portrait) {
            flex-flow : row-reverse;
        }
    }

    .about-two .infos {
        color : $white;

        // $tablet-portrait
        @media (min-width : $tablet-portrait) {
            padding-left : 40px;
        }
    }
}
