.s-secure {
    .wrapper {
        margin-left  : auto;
        margin-right : auto;
        max-width    : 1230px;
    }

    .row {
        margin-bottom : -10px;
    }

    .secure-item {
        margin-bottom : 10px;
    }
}
