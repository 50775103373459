.s-guarantee {
    img {
        max-width : 200px;
    }

    // $notebook
    @media (min-width : $notebook) {
        img {
            max-width : 300px;
        }
    }

    // $large-desktop
    @media (min-width : $large-desktop) {
        img {
            max-width : 100%;
        }
    }
}
