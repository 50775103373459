.s-testimonials {
    // $large-desktop
    @media (min-width : $large-desktop) {
        padding-top : 90px;
    }

    .row {
        margin-bottom : -20px;
    }

    .testimonials {
        overflow : visible;

        .swiper-wrapper {
            overflow : visible;
        }
    }

    .testimonial-item {
        margin-bottom : 20px;

        // $notebook
        @media (min-width: $notebook) {
            max-width: 250px;
        }
    }
}
