
@mixin animation($animation) {
    -webkit-animation : $animation;
    -o-animation      : $animation;
    animation         : $animation;
}

@mixin animation-name($name) {
    -webkit-animation-name : $name;
    animation-name         : $name;
}

@mixin animation-duration($duration) {
    -webkit-animation-duration : $duration;
    animation-duration         : $duration;
}

@mixin animation-timing-function($timing-function) {
    -webkit-animation-timing-function : $timing-function;
    animation-timing-function         : $timing-function;
}

@mixin animation-delay($delay) {
    -webkit-animation-delay : $delay;
    animation-delay         : $delay;
}

@mixin animation-iteration-count($iteration-count) {
    -webkit-animation-iteration-count : $iteration-count;
    animation-iteration-count         : $iteration-count;
}

@mixin animation-direction($direction) {
    -webkit-animation-direction : $direction;
    animation-direction         : $direction;
}

@mixin animation-fill-mode($fill-mode) {
    -webkit-animation-fill-mode : $fill-mode;
    animation-fill-mode         : $fill-mode;
}

@mixin backface-visibility($visibility) {
    -webkit-backface-visibility : $visibility;
    -moz-backface-visibility    : $visibility;
    backface-visibility         : $visibility;
}

@mixin box-shadow($shadow...) {
    -webkit-box-shadow : $shadow;
    box-shadow         : $shadow;
}

@mixin box-sizing($boxmodel) {
    -webkit-box-sizing : $boxmodel;
    -moz-box-sizing    : $boxmodel;
    box-sizing         : $boxmodel;
}

@mixin content-columns($column-count, $column-gap) {
    -webkit-column-count : $column-count;
    -moz-column-count    : $column-count;
    column-count         : $column-count;
    -webkit-column-gap   : $column-gap;
    -moz-column-gap      : $column-gap;
    column-gap           : $column-gap;
}

@mixin hyphens($mode: auto) {
    word-wrap       : break-word;
    -webkit-hyphens : $mode;
    -moz-hyphens    : $mode;
    -ms-hyphens     : $mode;
    -o-hyphens      : $mode;
    hyphens         : $mode;
}

@mixin placeholder($color) {
    &::-moz-placeholder {
        color   : $color;
        opacity : 1;
    }
    &:-ms-input-placeholder {
        color : $color;
    }
    &::-webkit-input-placeholder {
        color : $color;
    }
}

@mixin scale($ratio...) {
    -webkit-transform : scale($ratio);
    -ms-transform     : scale($ratio);
    -o-transform      : scale($ratio);
    transform         : scale($ratio);
}

@mixin scaleX($ratio) {
    -webkit-transform : scaleX($ratio);
    -ms-transform     : scaleX($ratio);
    -o-transform      : scaleX($ratio);
    transform         : scaleX($ratio);
}

@mixin scaleY($ratio) {
    -webkit-transform : scaleY($ratio);
    -ms-transform     : scaleY($ratio);
    -o-transform      : scaleY($ratio);
    transform         : scaleY($ratio);
}

@mixin skew($x, $y) {
    -webkit-transform : skewX($x) skewY($y);
    -ms-transform     : skewX($x) skewY($y);
    -o-transform      : skewX($x) skewY($y);
    transform         : skewX($x) skewY($y);
}

@mixin translate($x, $y) {
    -webkit-transform : translate($x, $y);
    -ms-transform     : translate($x, $y);
    -o-transform      : translate($x, $y);
    transform         : translate($x, $y);
}

@mixin translate3d($x, $y, $z) {
    -webkit-transform : translate3d($x, $y, $z);
    transform         : translate3d($x, $y, $z);
}

@mixin rotate($degrees) {
    -webkit-transform : rotate($degrees);
    -ms-transform     : rotate($degrees);
    -o-transform      : rotate($degrees);
    transform         : rotate($degrees);
}

@mixin rotateX($degrees) {
    -webkit-transform : rotateX($degrees);
    -ms-transform     : rotateX($degrees);
    -o-transform      : rotateX($degrees);
    transform         : rotateX($degrees);
}

@mixin rotateY($degrees) {
    -webkit-transform : rotateY($degrees);
    -ms-transform     : rotateY($degrees);
    -o-transform      : rotateY($degrees);
    transform         : rotateY($degrees);
}

@mixin perspective($perspective) {
    -webkit-perspective : $perspective;
    -moz-perspective    : $perspective;
    perspective         : $perspective;
}

@mixin perspective-origin($perspective) {
    -webkit-perspective-origin : $perspective;
    -moz-perspective-origin    : $perspective;
    perspective-origin         : $perspective;
}

@mixin transform($origin) {
    -webkit-transform : $origin;
    -moz-transform    : $origin;
    -ms-transform     : $origin;
    -o-transform      : $origin;
    transform         : $origin;
}

@mixin transform-origin($origin) {
    -webkit-transform-origin : $origin;
    -moz-transform-origin    : $origin;
    -ms-transform-origin     : $origin;
    transform-origin         : $origin;
}

@mixin transition($transition...) {
    -webkit-transition : $transition;
    -o-transition      : $transition;
    transition         : $transition;
}

@mixin transition-property($transition-property...) {
    -webkit-transition-property : $transition-property;
    transition-property         : $transition-property;
}

@mixin transition-delay($transition-delay) {
    -webkit-transition-delay : $transition-delay;
    transition-delay         : $transition-delay;
}

@mixin transition-duration($transition-duration...) {
    -webkit-transition-duration : $transition-duration;
    transition-duration         : $transition-duration;
}

@mixin transition-timing-function($timing-function) {
    -webkit-transition-timing-function : $timing-function;
    transition-timing-function         : $timing-function;
}

@mixin transition-transform($transition...) {
    -webkit-transition : -webkit-transform $transition;
    -moz-transition    : -moz-transform $transition;
    -o-transition      : -o-transform $transition;
    transition         : transform $transition;
}

@mixin user-select($select) {
    -webkit-user-select : $select;
    -moz-user-select    : $select;
    -ms-user-select     : $select;
    user-select         : $select;
}

@mixin border-radius($radius) {
    -webkit-border-radius : $radius;
    -moz-border-radius    : $radius;
    border-radius         : $radius;
}
