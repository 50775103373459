.s-details {
    .wrapper {
        display   : flex;
        flex-wrap : wrap;
        flex-direction: column;

        // $tablet
        @media (min-width : $tablet) {
            align-items : center;
        }

        // $notebook
        @media (min-width: $notebook) {
            flex-direction: row;
        }
    }

    .image {
        flex          : 0 0 100%;
        order         : 1;
        margin-bottom : 20px;
        text-align    : center;

        img {
            max-width : 90%;
        }

        // $tablet
        @media (min-width : $tablet) {
            img {
                max-width : 350px;
            }
        }

        // $tablet-portrait
        @media (min-width : $tablet-portrait) {
            img {
                max-width : 380px;
            }
        }

        // $notebook
        @media (min-width : $notebook) {
            max-width    : 100%;
            margin-right : -20px;
            margin-left  : -20px;
            flex  : 1;
            order : 2;

            img {
                max-width: 420px;
            }
        }

        // $desktop
        @media (min-width : $desktop) {
            flex         : 0 0 auto;
            margin-right : 15px;
            margin-left  : 15px;
        }
    }

    .infos {
        // $tablet
        @media (min-width : $tablet) {
            flex : 1;
        }

        &.-left {
            order : 2;

            // $tablet
            @media (min-width : $tablet) {
            }

            // $notebook
            @media (min-width: $notebook) {
                order : 1;
            }
        }

        &.-right {
            order : 3;
        }
    }

    .infos {
        ul {
            list-style : none;
            margin     : 0;
            padding    : 0;

            li {
                display       : flex;
                font-weight   : 500;
                margin-bottom : 0.9375rem;
                //font-size     : 18px;

                &:before {
                    flex                : 0 0 20px;
                    margin-right        : 10px;
                    align-self          : flex-start;
                    content             : '';
                    width               : 20px;
                    height              : 20px;
                    background          : url("../images/check.png") no-repeat center center;
                    background-size     : cover;
                    background-position : center center;
                }

                // $tablet
                @media (min-width : $tablet) {
                    margin-bottom : 1.25rem;

                    &:before {
                        flex   : 0 0 28px;
                        width  : 28px;
                        height : 28px;
                    }
                }

                // $tablet-portrait
                @media (min-width : $tablet-portrait) {
                    //font-size     : 20px;
                    margin-bottom : 1.875rem;
                }

                // $notebook
                @media (min-width : $notebook) {
                    //font-size     : 24px;
                }

                p {
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }

        &.-left {
            // $tablet
            @media (min-width : $notebook) {
                ul {
                    text-align : right;

                    li {
                        flex-flow : row-reverse;

                        &:before {
                            margin-right : 0;
                            margin-left  : 10px;
                        }
                    }
                }
            }
        }
    }

    .waves {
        img {
            max-width : 120px;
        }

        // $tablet
        @media (min-width : $tablet) {
            img {
                max-width : 180px;
            }
        }

        // $notebook
        @media (min-width : $notebook) {
            img {
                max-width : 100%;
            }
        }
    }
}
