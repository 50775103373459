
.button-shipping {
    font-size        : 14px;
    font-weight      : 700;
    display          : inline-flex;
    align-items      : center;
    padding          : .35em 25px;
    transition       : all .4s ease;
    text-align       : left;
    text-transform   : uppercase;
    color            : $white;
    border-radius    : 10px;
    background-color : $booger;

    // 768
    @media (min-width : $tablet) {
        font-size : 12px;
    }

    // 992
    @media (min-width : $tablet-portrait) {
        font-size      : 14px;
        padding-top    : .8em;
        padding-bottom : .8em;
    }

    // 1600
    @media (min-width : $large-desktop) {
        padding : .65em 25px;
    }

    .icon {
        margin-right : 10px;

        svg {
            width : 43px;
            fill  : $white;
        }

        svg, g {
        }
    }

    &:hover {
        background-color : darken($booger, 10%);
        color            : $white;
    }
}
