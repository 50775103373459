/**
Colors
 */
$black                    : #000000;
$white                    : #ffffff;

$pea                      : #c3ca1e;
$white-two                : #ebebeb;
$mud-brown                : #592e0c;
$mud-brown-three          : #f89f09;
$black                    : #262626;
$black-two                : #333333;
$booger                   : #88a83d;
$pea-two                  : #c3cb20;
$tealish-green            : #07d973;
$black-three              : #3a3a3a;
$dirt-brown               : #68442a;
$greyish-brown            : #403a2e;
$pale-peach               : #feebb8;
$pale-peach-two           : #fde3b0;
$green-brown              : #515400;
$puke-yellow              : #b8c011;
$greyish                  : #b2b2b2;
$irish-green              : #009a1d;
$irish-green-two          : #0c4a08;
$green                    : #0e9130;
$orange                   : #ed6104;

$blue                     : #089adb;
$blue-600                 : #04448d;
$yellow                   : #febe07;

$purple-400               : #B71375;
$purple-500               : #8B1874;
$orange-500               : #FC4F00;
$orange-300               : #F79540;

/**
Breakpoints
 */
$mobile                   : 576px;
$tablet                   : 768px;
$tablet                   : 768px;
$tablet-portrait          : 992px;
$notebook                 : 1200px;
$desktop                  : 1400px;
$large-desktop            : 1600px;

/**
Override
 */
$body-color               : $black;

// theme colors
$primary                  : $blue;
$secondary                : $yellow;
$success                  : $green;

// prefix
$variable-prefix          : '';

// grid
$grid-gutter-width        : 30px;

/**
Spaces
 */
// add more spaces to bootstrap spaces
$spacer                   : 1rem !default;
$spacers                  : () !default;
$spacers                  : map-merge(
                (
                        0: 0,
                        1: ($spacer * .25),
                        2: ($spacer * .5),
                        3: $spacer,
                        4: ($spacer * 1.5),
                        5: ($spacer * 3),
                        6: ($spacer * 3.5),
                        7: ($spacer * 4),
                        8: ($spacer * 4.5),
                        9: ($spacer * 5),
                        10: ($spacer * 5.5),
                        11: ($spacer * 6),
                        12: ($spacer * 6.5),
                        13: ($spacer * 7),
                        14: ($spacer * 7.5),
                        15: ($spacer * 8),
                ),
                $spacers
);

/**
Cards
 */
$card-border-color        : $white-two;


/**
Forms
 */
$form-switch-input-width  : 48px;
$form-switch-input-height : 1.5rem
