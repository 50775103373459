.widget {
    > .title {
        font-weight : 400;
        color       : $white;
    }

    > .content {
        color : $white;

        a {
            color : $white;
        }
    }
}
