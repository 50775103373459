.stories-video {
    position         : relative;
    border-radius    : 10px;
    overflow         : hidden;
    border           : 5px solid $secondary;
    background-color : $secondary;

    img, video {
        vertical-align : top;
        min-height     : 300px;

        // $notebook
        @media (min-width : $notebook) {
            min-height : 400px;
        }
    }

    .play {
        position        : absolute;
        left            : 0;
        right           : 0;
        bottom          : 0;
        top             : 0;
        display         : flex;
        align-items     : center;
        justify-content : center;
        color           : $white;
        cursor          : pointer;

        svg {
            position   : relative;
            z-index    : 1;
            transition : all .4s ease;
        }

        &:before {
            content       : '';
            display       : block;
            position      : absolute;
            width         : 55px;
            height        : 55px;
            border-radius : 55px;
            background    : rgba(0, 0, 0, .5);
        }

        &:hover {
            svg {
                transform : scale(1.1);
            }
        }
    }

    .plyr__poster {
        background-size : cover;
    }

    img {
        object-fit : cover;
    }
}
