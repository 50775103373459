.container {
    display       : block;
    margin-right  : auto;
    margin-left   : auto;
    padding-right : 25px;
    padding-left  : 25px;
    max-width     : 480px;
    width         : 100%;

    @media (min-width : $tablet) {
        max-width     : 760px;
        padding-left  : 15px;
        padding-right : 15px;
    }

    @media (min-width : $notebook) {
        max-width : 1170px;
    }

    @media (min-width : $large-desktop) {
        max-width : 1430px;
    }
}
