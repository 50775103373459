/**
Vendors
 */
// plyr
@import "../../../node_modules/plyr/dist/plyr";

// bootstrap
@import "../../../node_modules/bootstrap/scss/functions";

/**
Settings
 */
@import "settings/s.variables";

/**
Tools
 */
@import "tools/tls.mixins";
@import "tools/tls.types";

/**
Swiper
 */
@import "../../../node_modules/swiper/swiper";

/**
Bootstrap
 */
@import '../../../node_modules/bootstrap/scss/bootstrap';

//@import "../../../node_modules/bootstrap/scss/variables";
//@import "../../../node_modules/bootstrap/scss/mixins";
//@import "../../../node_modules/bootstrap/scss/utilities";
//@import "../../../node_modules/bootstrap/scss/root";
//@import "../../../node_modules/bootstrap/scss/grid";
//@import "../../../node_modules/bootstrap/scss/utilities/api";

/**
Generic
 */
@import "generic/g.reset";

/**
Base
 */
@import "base/b.body";
@import "base/b.image-responsive";
@import "base/b.links";

/**
Objects
 */
@import "objects/o.container";
@import "objects/o.s-about";
@import "objects/o.s-all";
@import "objects/o.s-details";
@import "objects/o.s-faq";
@import "objects/o.s-footer";
@import "objects/o.s-footer-success";
@import "objects/o.s-kits";
@import "objects/o.s-main";
@import "objects/o.s-secure";
@import "objects/o.s-success";
@import "objects/o.s-testimonial";
@import "objects/o.s-video";
@import 'objects/o.s-half';
@import 'objects/o.s-timer';
@import 'objects/o.s-checkout';
@import 'objects/o.s-access';
@import 'objects/o.s-guarantee';
@import 'objects/o.s-vivo';
@import 'objects/o.s-list-product';

/**
Components
 */
@import 'components/c.form-check';
@import 'components/c.form-switch';
@import 'components/c.card';
@import 'components/c.card-step';
@import 'components/c.card-survey';
@import "components/c.accordion-item";
@import "components/c.button-shipping";
@import "components/c.buttons";
@import "components/c.entry";
@import "components/c.free-shipping";
@import "components/c.heading";
@import "components/c.secure-item";
@import "components/c.table-item";
@import "components/c.testimonial-item";
@import "components/c.video";
@import "components/c.widget";
@import 'components/c.check-list';
@import 'components/c.coupon';
@import 'components/c.stories-video';
@import 'components/c.instagram-grid';

/**
Trumps
 */
@import "trumps/t.animations";
@import "trumps/t.embed-responsive";
@import 'trumps/t.weight';
@import 'trumps/t.swiper';
