.heading {
    //@include leaves;
    font-size   : 1.75rem;

    // $tablet
    @media (min-width : $tablet) {
        font-size : 2rem;
    }

    // $tablet-portrait
    @media (min-width : $tablet-portrait) {
        font-size : 2.25rem;
    }

    // $notebook
    @media (min-width : $notebook) {
        font-size : 3rem;
    }

    &-2 {
        font-size   : 1.55rem;

        // $tablet
        @media (min-width : $tablet) {
            font-size : 1.75rem;
        }

        // $tablet-portrait
        @media (min-width : $tablet-portrait) {
            font-size : 2rem;
        }

        // $notebook
        @media (min-width : $notebook) {
            font-size : 2.75rem;
        }
    }
}
