.secure-item {
    border           : 2px solid rgba(255, 255, 255, .25);
    background-color : rgba(255, 255, 255, .10);
    border-radius    : 10px;
    display          : flex;
    padding          : 10px 24px;
    align-items      : center;
    gap              : 12px;
    text-align       : left;

    // $tablet
    @media (min-width : $tablet) {
        flex-flow  : column;
        text-align : center;
        width      : 33.333333%;
        text-align : center;
    }

    // $tablet-portrait
    @media (min-width : $tablet-portrait) {
        padding : 12px 12px;
    }

    .icon {
        flex        : 0 0 auto;
        align-self  : flex-start;
        max-width   : 42px;
        max-height  : 42px;
        display     : flex;
        align-items : center;
        color       : $secondary;

        svg, path {
            max-width  : 100%;
            max-height : 100%;
            width      : auto;
            height     : auto;
        }

        // $tablet
        @media (min-width : $tablet) {
            margin-bottom : 10px;
            margin-left   : auto;
            margin-right  : auto;
        }

        // $tablet-portrait
        @media (min-width : $tablet-portrait) {
            align-self    : center;
            margin-bottom : 0;
        }
    }

    .infos {
    }

    .infos > .name {
        font-weight : 700;
        font-size   : 15px;

        // $tablet
        @media (min-width : $tablet) {
            font-size : 16px;
        }
    }

    .infos > .content {
        font-size   : 13px;
        line-height : 1.44;

        p {
            &:last-child {
                margin-bottom : 0;
            }
        }
    }
}
